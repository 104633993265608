import { createDebugLogger } from "./initDebug";
const debug = true;
const consoleLog = createDebugLogger(debug);

export default function initOffcanvas() {
    const backdrop = document.createElement("div");
    backdrop.classList.add("offcanvas__backdrop");

    let offcanvasStack = []; // Stack to track open offcanvas elements
    let backdropVisible = false;

    const toggleBackdropVisibility = (show) => {
        backdropVisible = show;
        if (show) {
            if (!document.body.contains(backdrop))
                document.body.appendChild(backdrop);
            requestAnimationFrame(() => {
                backdrop.classList.add("offcanvas__backdrop--is-active");
            });
            consoleLog("%cBackdrop is now active.", "info");
            backdrop.addEventListener("click", () => closeOffcanvas(true), {
                once: true,
            });
        } else {
            backdrop.classList.remove("offcanvas__backdrop--is-active");
            consoleLog("%cInitiating backdrop removal.", "info");
            backdrop.addEventListener(
                "transitionend",
                () => {
                    if (!backdropVisible && document.body.contains(backdrop)) {
                        document.body.removeChild(backdrop);
                        consoleLog(
                            "%cBackdrop finished transitioning, hence it was fully removed from the DOM.",
                            "warning"
                        );
                    }
                },
                { once: true }
            );
        }
    };

    const updateBodyClass = () => {
        document.body.classList.toggle(
            "offcanvas--is-inview",
            offcanvasStack.length > 0
        );
    };

    const closeOffcanvas = (fromBackdrop = false) => {
        const activeOffcanvas = offcanvasStack.pop();
        if (activeOffcanvas) {
            activeOffcanvas.classList.remove("offcanvas--is-active");
            const activeToggle = document.querySelector(
                `[data-target="${activeOffcanvas.id}"]`
            );
            activeToggle.classList.remove("menu-toggler--is-active");
            consoleLog(
                `Offcanvas %c${activeOffcanvas.id}%c is now %chidden%c.`,
                "error"
            );

            if (offcanvasStack.length === 0 || fromBackdrop) {
                toggleBackdropVisibility(false);
            }
            updateBodyClass();
        } else {
            consoleLog("%cNo active offcanvas to close.", "warning");
        }
    };

    const toggleOffcanvas = (toggler) => {
        const targetElement = document.getElementById(
            toggler.getAttribute("data-target")
        );

        if (!targetElement) {
            consoleLog("%cOffcanvas target not found.", "error");
            return;
        }

        const targetLevel = targetElement.getAttribute("data-level");

        document
            .querySelectorAll(
                `[data-level='${targetLevel}'].offcanvas--is-active`
            )
            .forEach((activeElement) => {
                if (activeElement !== targetElement) {
                    activeElement.classList.remove("offcanvas--is-active");
                    consoleLog(
                        `%cClosed other level ${targetLevel} offcanvas to open new one.`,
                        "info"
                    );
                }
            });

        const isActive = targetElement.classList.toggle("offcanvas--is-active");
        toggler.classList.toggle("menu-toggler--is-active", isActive);

        if (isActive) {
            offcanvasStack.push(targetElement);
        } else {
            offcanvasStack = offcanvasStack.filter(
                (oc) => oc !== targetElement
            );
        }

        toggleBackdropVisibility(offcanvasStack.length > 0);
        updateBodyClass();

        consoleLog(
            `Offcanvas %c${targetElement.id}%c is now %c${
                isActive ? "visible" : "hidden"
            }%c.`,
            isActive ? "success" : "error"
        );
    };

    const showOffcanvas = (toggler) => {
        const targetElement = document.getElementById(
            toggler.getAttribute("data-target")
        );
        if (!targetElement) {
            consoleLog("%cOffcanvas target not found.", "error");
            return;
        }

        const isActive = targetElement.classList.contains(
            "offcanvas--is-active"
        );

        if (!isActive) {
            toggleOffcanvas(toggler);
        }
    };

    const hideOffcanvas = (toggler) => {
        const targetElement = document.getElementById(
            toggler.getAttribute("data-target")
        );
        if (!targetElement) {
            consoleLog("%cOffcanvas target not found.", "error");
            return;
        }

        const isActive = targetElement.classList.contains(
            "offcanvas--is-active"
        );

        if (isActive) {
            toggleOffcanvas(toggler);
        }
    };

    document
        .querySelectorAll('[data-toggle="offcanvas"]')
        .forEach((toggler) => {
            if (
                toggler.classList.contains("offcanvas__hover-toggle") &&
                IS_MNK
            ) {
                toggler.addEventListener("mouseenter", () =>
                    showOffcanvas(toggler)
                );
                // Optionally, close them on leave
                // toggler.addEventListener("mouseleave", () =>
                //     hideOffcanvas(toggler)
                // );

                // toggler.addEventListener("focus", () => showOffcanvas(toggler));
                // toggler.addEventListener("blur", () => hideOffcanvas(toggler));
            } else {
                toggler.addEventListener("click", (e) => {
                    toggleOffcanvas(toggler);
                    e.stopPropagation();
                });
            }
        });

    document.addEventListener("keydown", (event) => {
        if (
            (event.key === "Escape" || event.keyCode === 27) &&
            offcanvasStack.length > 0
        ) {
            consoleLog("%cESC key pressed. Closing offcanvas.", "warning");
            closeOffcanvas();
        }
    });
}
