import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CustomEase);

export default function initGsap() {
    gsap.defaults({
        ease: CustomEase.create("cubicMild", "M0,0 C0.8,0.2, 0.2,0.8, 1,1"),
    });

    ScrollSmoother.create({
        effects: IS_MNK, // Default: "false"
        smooth: 1.2, // Default: 0.8
        // speed: 1.5, // Default: 1
        // ease: "cubicMild", // Default: "expo"
        // smoothTouch: 0.1, // Default: "false"
        // ignoreMobileResize: true, // Default: "false"
    });

    if (IS_MNK) {
        ScrollTrigger.normalizeScroll({
            allowNestedScroll: true,
        });
    }
}
