/* +-----------------------------------------+
|           GLOBALS AREA             		 |
+-----------------------------------------+ */

import { ScrollTrigger } from "gsap/ScrollTrigger";

const debug = false;
window.IS_RTL = document.documentElement.getAttribute("dir") === "rtl";
window.IS_MNK = !ScrollTrigger.isTouch;
window.IS_TOUCH = !!ScrollTrigger.isTouch;

/* +-----------------------------------------+
|           IMPORTS AREA             		 |
+-----------------------------------------+ */

// CSS
import "../scss/frontend.scss";

// Elementor
import setParallax from "./libs/elementor/setParallax";
import removeInert from "./libs/elementor/removeInert";

// GSAP
import initGsap from "./libs/gsap/initGsap";
import initTriggers from "./libs/gsap/initTriggers";

// Utilities
import exitLoader from "./utils/exitLoader";
import getDimensions from "./utils/getDimensions";
import initOffcanvas from "./utils/initOffcanvas";
// import initVideos from "./utils/initVideos";
import bookingForm from "./utils/bookingForm";
import FPSMeter from "./utils/fpsMeter.js";
import { scrollTo } from "./libs/gsap/gsapUtils";

/* +-----------------------------------------+
|           EVENTS AREA             		 |
+-----------------------------------------+ */

// DOC Ready
function documentReady() {
    initOffcanvas();
    // initVideos();

    getDimensions("#site-header", "height");
    getDimensions(".single-product__navigation", "height");

    setParallax();
    initGsap();
    initTriggers();

    if (debug) new FPSMeter();
}
document.addEventListener("DOMContentLoaded", documentReady, false);

// WINDOW Load
function windowLoad() {
    getDimensions("#site-footer", "height");

    removeInert();

    exitLoader("#siteLoader");
}
window.addEventListener("load", windowLoad, false);

// WINDOW Scroll
// function windowScroll() {}
// window.addEventListener("scroll", windowScroll, { passive: true });

// WINDOW Resize
// function windowResize() {}
// window.addEventListener("resize", windowResize, false);

// WS Form
(function ($) {
    $(document).on("wsf-rendered", function (e, form, form_id, instance_id) {
        new bookingForm(form);
        getDimensions(".iti__flag-container", "width");
    });
    $(document).on("wsf-tab-clicked", function (e, form, form_id, instance_id) {
        getDimensions(".iti__flag-container", "width");
    });

    $(document).on(
        "wsf-submit-before",
        function (e, form, form_id, instance_id, form_el_canvas) {
            scrollTo(form_el_canvas[0]);
        }
    );
})(jQuery);
