import { gsap, ScrollTrigger, SplitText } from "gsap/all";
import gsapAnimations from "../animations/animationsIndex";

const debug = false;

// Register GSAP plugins for use
gsap.registerPlugin(ScrollTrigger, SplitText);

/**
 * Initializes animations on elements with the 'aos' attribute using GSAP.
 */
export default function initGsapAos() {
    /**
     * Fetches attribute values with a default fallback.
     * @param {HTMLElement} el - The element to fetch attributes from.
     * @param {string} attrName - The attribute name to fetch.
     * @param {*} [defaultValue] - The default value if the attribute is not set.
     * @returns {*} The attribute value or the default value.
     */
    function getAttribute(el, attrName, defaultValue = null) {
        const value = el.getAttribute(attrName);
        return value !== null
            ? isNaN(value)
                ? value
                : parseFloat(value)
            : defaultValue;
    }

    /**
     * Animates a single HTML element based on data attributes.
     * @param {HTMLElement} el - The element to animate.
     */
    function gsapAos(el) {
        const animationType = getAttribute(el, "aos");
        if (!gsapAnimations[animationType]) {
            console.error(`Invalid animation type: ${animationType}`);
            return;
        }

        let { origin, target } = gsapAnimations[animationType];
        const isReverse = el.hasAttribute("aos-reverse");

        // Set initial scroll trigger positions, reverse if needed
        let defaultStart = isReverse ? "center center" : "top bottom";
        let defaultEnd = isReverse ? "bottom top" : "center center";
        if (isReverse) {
            [origin, target] = [target, origin];
        }

        const duration = getAttribute(el, "aos-duration", 1);
        const delay = getAttribute(el, "aos-delay", 0.2);
        const start = getAttribute(el, "aos-start", defaultStart);
        const end = getAttribute(el, "aos-end", defaultEnd);
        const repeat = el.hasAttribute("aos-repeat");
        const scrub = el.hasAttribute("aos-scrub");

        // Text splitting configuration
        let splitType = getAttribute(el, "split-type", "words");
        const splitStagger = getAttribute(el, "split-stagger", 0.05);
        const splitFrom = getAttribute(el, "split-from", "start");

        if (IS_TOUCH && splitType === "chars") splitType = "words";
        if (IS_RTL && animationType.includes("split")) splitType = "lines";

        let animatedElement = el;
        if (animationType.includes("split")) {
            const split = new SplitText(el, { type: splitType });
            gsap.set(split[splitType], origin);
            animatedElement = split[splitType];
        } else {
            gsap.set(el, origin);
        }

        // Execute the animation with GSAP
        gsap.to(animatedElement, {
            ...target,
            duration,
            delay,
            // ease: "Power3.inOut",
            stagger: animationType.includes("split")
                ? { each: splitStagger, from: splitFrom }
                : undefined,
            scrollTrigger: {
                trigger: el,
                markers: debug,
                start,
                end,
                toggleActions: repeat
                    ? "play none none reverse"
                    : "play none none none",
                scrub,
                once: !(scrub || repeat),
            },
        });
    }

    // Apply the animation setup to all elements with the 'aos' attribute
    document.querySelectorAll("[aos]").forEach(gsapAos);
}
