import { gsap, ScrollSmoother } from "gsap/all";

export function scrollTo(
    target,
    smooth = true,
    position = `top ${gsap.getProperty(":root", "--site-header-height") * 1.5}`
) {
    const smoother = ScrollSmoother.get();

    if (typeof target === "number") {
        // If target is a number, treat it as a pixel position
        smoother.scrollTo(target, smooth);
    } else if (target instanceof Element) {
        // If target is a DOM element, scroll to it
        smoother.scrollTo(target, smooth, position);
    } else {
        console.warn("Invalid target for scrollTo: ", target);
    }
}
