import initGsapAos from "./triggers/gsapAos";
import initGsapIsElementSticked from "./triggers/gsapIsElementSticked";
// import initGsapIsElementInview from "./triggers/gsapIsElementInview";
// import initGsapGetScrollDirection from "./triggers/gsapGetScrollDirection";
import initGsapSticker from "./triggers/gsapSticker";
import initGsapOdometer from "./triggers/gsapOdometer";
import initGsapRevealer from "./triggers/gsapRevealer";

export default function initTriggers() {
    if (IS_MNK) {
        initGsapAos();

        // setTimeout is only required because of Elementor x Lottie Integration
        // setTimeout(() => {
        initGsapSticker();
        // }, 200);

        initGsapRevealer();
    }
    initGsapIsElementSticked("#site-header");
    // initGsapIsElementInview("#site-footer");
    // initGsapGetScrollDirection();
    if (!IS_RTL) initGsapOdometer();
}
