import { createDebugLogger } from "../../utils/initDebug";
const debug = false;
const consoleLog = createDebugLogger(debug);

const removeInert = () => {
    const config = { attributes: true, childList: false, subtree: true };

    const callback = (mutationsList) => {
        mutationsList.forEach((mutation) => {
            if (mutation.attributeName !== "inert") return;

            const target = mutation.target;
            if (
                target.classList.contains("swiper-slide") &&
                target.hasAttribute("inert")
            ) {
                target.removeAttribute("inert");
                target.removeAttribute("aria-hidden");
                consoleLog(
                    "Removed %cinert%c & %caria-hidden='true'%c from a Swiper slide.",
                    "success"
                );
            }
        });
    };

    const containers = document.querySelectorAll(
        ".e-widget-swiper--overflow-visible"
    );

    if (containers.length) {
        containers.forEach((container) => {
            const observer = new MutationObserver(callback);
            observer.observe(container, config);
        });
        consoleLog(
            `Observer initialized for %c${containers.length}%c Swiper containers.`,
            "success"
        );
    } else {
        consoleLog("%cNo targeted Swiper containers found.", "error");
    }
};

export default removeInert;
