import { gsap, ScrollTrigger } from "gsap/all";
import Odometer from "odometer";

// Global Odometer options
window.odometerOptions = {
    auto: false, // Don't automatically initialize everything with class 'odometer'
    // format: "(’ddd),dd", // Change how digit groups are formatted, and how many digits are shown after the decimal point
    // animation: "count", // Count is a simpler animation method which just increments the value,
};

export default function initGsapOdometer() {
    function gsapOdometer(element) {
        const odometerOriginValue = element.getAttribute(
            "odometer-origin-value"
        );
        const odometerTargetValue = element.getAttribute(
            "odometer-target-value"
        );
        const odometerFormat = element.getAttribute("odometer-format") || "d";

        if (odometerTargetValue) {
            const odometer = new Odometer({
                el: element,
                value: odometerOriginValue,
                format: odometerFormat,
            });

            odometer.update(odometerTargetValue);
        }
    }

    gsap.utils.toArray(".odometer").forEach((element) => {
        ScrollTrigger.create({
            trigger: element,
            onEnter: (self) => {
                gsapOdometer(element);
                self.kill();
            },
        });
    });
}
