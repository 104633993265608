export default {
    blur: {
        origin: { backdropFilter: "blur(12px)" },
        target: { backdropFilter: "blur(0px)" },
    },
    darken: {
        origin: { backdropFilter: "brightness(0)" },
        target: { backdropFilter: "brightness(1)" },
    },
};
