import { scrollTo } from "../libs/gsap/gsapUtils";

export default class bookingForm {
    constructor(form = false) {
        if (form && form.meta.class_form_wrapper == "booking-form") {
            document.querySelectorAll(".booking-form").forEach((e) => {
                this.defineGridEvent(e);
                this.clearCustomGridSelection(e);
                this.tabAnimations(e);
            });
        }

        // this.fillCheckoutForm();
    }

    fillCheckoutForm() {
        function getCookie(name) {
            let value = "; " + document.cookie;
            let parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(";").shift();
        }

        function fillCheckoutField(fieldId, cookieName) {
            let value = getCookie(cookieName);
            if (value) {
                let field = document.querySelector(fieldId);
                if (field) {
                    field.value = value;
                    field.setAttribute("value", value);
                    field.dispatchEvent(new Event("change"));
                }
            }
        }

        // Mapea las cookies a los campos del checkout
        fillCheckoutField("#billing-first_name", "billing_first_name");
        fillCheckoutField("#billing-last_name", "billing_last_name");
        fillCheckoutField("#billing-email", "billing_email");
        fillCheckoutField("#booking_price", "booking_price");
        fillCheckoutField("#booking_name", "booking_name");
    }

    clearCustomGridSelection(e) {
        document.addEventListener("custom-reset", () => {
            e.querySelectorAll(".grid-select").forEach((gridSelect) => {
                gridSelect
                    .querySelectorAll(".grid-select__item")
                    .forEach((item) => item.classList.remove("active"));
            });
        });
    }

    tabAnimations(e) {
        jQuery(document).on(
            "wsf-tab-clicked",
            function (event, form_object, form_id, instance_id) {
                let tabs = e.querySelectorAll(".wsf-group");

                tabs.forEach((e) => {
                    e.classList.remove("transition");
                    e.style.opacity = "0";
                });

                setTimeout(() => {
                    tabs.forEach((e) => {
                        e.classList.add("transition");
                        e.style.opacity = 1;

                        scrollTo(document.querySelector(".wsf-group-tabs"));
                    });
                }, 1);
            }
        );
    }

    defineGridEvent(e) {
        e.querySelectorAll(".grid-select").forEach((gridSelect) => {
            gridSelect
                .querySelectorAll(".grid-select__item")
                .forEach((item) => this.optionClick(item, gridSelect));
        });
    }

    optionClick(item, gridSelect) {
        item.addEventListener("click", (e) => {
            // Clean active items
            gridSelect
                .querySelectorAll(".grid-select__item")
                .forEach((item) => item.classList.remove("active"));

            // Set new active item
            let titleElement = item.querySelector("h2");
            let elBasePrice = item.querySelector(".grid-select__price");
            let title = titleElement ? titleElement.textContent : false;
            let price = elBasePrice
                ? parseFloat(
                      elBasePrice.textContent
                          .replace("CHF", "")
                          .replace(/’/g, "")
                          .trim()
                  )
                : 0;
            let hiddenField = gridSelect.querySelector(".grid-select__input");
            let hiddenPriceField = gridSelect.querySelector(
                ".grid-select__input-price"
            );
            let tab = item.closest(".wsf-group");

            // Retrieve the total nights selected by the user
            const totalNights = parseInt(
                document.querySelector(".booking-form__total-nights").value
            );

            // Find the li element that matches the selected number of nights using data attributes
            const additionalNights = Array.from(
                item.querySelectorAll("li[data-nights]")
            ).find(
                (element) => parseInt(element.dataset.nights) === totalNights
            );

            // Extract the price from the matching li element using data attributes
            const additionalPrice = additionalNights
                ? parseFloat(additionalNights.dataset.price)
                : 0;

            // Calculate the total price
            const totalPrice = price + additionalPrice;

            var event = new Event("change", {
                bubbles: true, // Para que el evento burbujee (opcional)
                cancelable: true, // Para que el evento se pueda cancelar (opcional)
            });

            if (title) {
                hiddenField.value = title;
                // Despachar (disparar) el evento 'change' en el input
                hiddenField.dispatchEvent(event);
                item.classList.add("active");
            }

            if (totalPrice) {
                hiddenPriceField.value = totalPrice;

                // Despachar (disparar) el evento 'change' en el input
                hiddenPriceField.dispatchEvent(event);
            }

            scrollTo(tab.querySelector(".summary"));
        });
    }
}
