import { gsap, ScrollTrigger } from "gsap/all";

const debug = false;

export default function initGsapSticker() {
    function gsapSticker(track) {
        const target = track.querySelector("[sticky-item]");
        if (!target) return;

        const stickyPosition = target.getAttribute("sticky-position");
        const headerHeight = gsap.getProperty(":root", "--site-header-height");

        let start, end;

        switch (stickyPosition) {
            case "top":
                start = () => `-=${headerHeight * 1.5}`;
                end = () => `+=${track.offsetHeight - target.offsetHeight}`;
                break;
            case "top-center":
                start = () => `top center`;
                end = () =>
                    `${track.offsetHeight - target.offsetHeight} center`;
                break;
            case "center":
                start = () =>
                    `${target.offsetHeight / 2 - headerHeight / 2} center`;
                end = () =>
                    `${
                        track.offsetHeight -
                        target.offsetHeight / 2 -
                        headerHeight / 2
                    } center`;
                break;
            case "bottom":
                start = () => `${target.offsetHeight} center`;
                end = () => `${track.offsetHeight} center`;
                break;
            case "header":
                start = () => `-=${headerHeight}`;
                end = () => `+=${track.offsetHeight - target.offsetHeight}`;
                break;
            default:
                start = () => `-=${headerHeight * 1.5}`;
                end = () => `+=${track.offsetHeight - target.offsetHeight}`;
                break;
        }

        ScrollTrigger.create({
            id: `Sticking-${stickyPosition || "default"}`,
            markers: debug,

            pin: target,
            pinSpacing: false,

            trigger: track,
            start: start,
            end: end,
            toggleClass: { targets: target, className: "is-stuck" },
        });
    }

    gsap.utils.toArray("[sticky-track]").forEach((track) => gsapSticker(track));
}
