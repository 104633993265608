import { gsap, ScrollTrigger } from "gsap/all";

const debug = false;

export default function initGsapRevealer() {
    function gsapRevealer(container) {
        const target = container.querySelector("[reveal-item]");
        if (!target) return;

        gsap.set(target, { yPercent: -100 });
        const reveal = gsap.timeline({ paused: true });

        reveal.to(target, {
            yPercent: 0,
            ease: "none",
        });

        ScrollTrigger.create({
            id: "Revealing",
            markers: debug,

            animation: reveal,
            scrub: true,

            trigger: container,
            start: "top bottom",
            end: () => `+=${container.offsetHeight}`,
        });
    }

    gsap.utils
        .toArray("[reveal-container]")
        .forEach((container) => gsapRevealer(container));
}
